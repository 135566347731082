import getLastSlug from '@/utils/filters/getLastSlug';

const newRemovedFilters = (type, multiple, slug, filtersActive) => {
  if (slug === filtersActive.keywords) {
    filtersActive.keywords = '';
    filtersActive.last_slug = getLastSlug(filtersActive, slug);
    return filtersActive;
  }
  let filtersActiveType;
  if (!multiple) {
    filtersActiveType = '';
  } else {
    filtersActiveType = [...filtersActive[type]];
    const index = filtersActive[type]?.indexOf(slug);
    if (index >= 0) filtersActiveType.splice(index, 1);
  }
  filtersActive[type] = filtersActiveType;
  filtersActive.last_slug = getLastSlug(filtersActive, slug);
  return filtersActive;
};
export default newRemovedFilters;
