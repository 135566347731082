/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SizeItem from '@/components/atoms/SizeItem';

const FilterOptionSize = (props) => {
  const { name, onClick } = props;

  const [selected, setSelected] = useState(props.selected);

  const handleClick = () => {
    setSelected(!selected);
    onClick();
  };

  return (
    <SizeItem
      onClick={handleClick}
      isSelected={selected}
      size={name}
    />
  );
};
FilterOptionSize.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool
};
FilterOptionSize.defaultProps = {
  selected: false
};
export default FilterOptionSize;
