import router from 'next/router';
import createQueryURL from './createQueryURL';
import pushToMicroSite from '../pushToMicroSite';

function generalizeProperties(filters, orderBy, page) {
  return {
    ...filters,
    slugs: filters.slugs,
    ordenar: orderBy,
    page
  };
}

// esta funcion se encarga de hacer el router.push() al aplicar un filtro
const routerPushWithQuery = (filters, orderBy, filterOptions, page = 1) => {
  // obtenemos el objeto de filtros con las propiedades ya generalizadas
  const query = generalizeProperties(filters, orderBy, page);

  // creamos el pathname (lo necesita nextjs)
  // y la url que se va a mostrar en el navegador
  const { newUrl } = createQueryURL(query, filterOptions, filters);
  router.push(pushToMicroSite() + newUrl);
};
export default routerPushWithQuery;
